import { Injectable } from '@angular/core';
import { HttpClientService } from '../../utility/http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { serviceUrl } from 'src/app/utility/serviceUrl';
import { QmaConstant } from 'src/app/constant/qma-constant';
const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': 'https://qa-qmaui.dev.qwyn.deltacapita.net',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
    'Access-Control-Allow-Credentials': 'true'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DatabaseAdminService extends HttpClientService {

  constructor(http: HttpClient) {
    super(http);
  }
  getAdminDbCollections(): Observable<any> {
    return this.get(serviceUrl.getAdminDbCollections);
  }

  getDBDocumentForID(requestObj: any): Observable<any> {
    let request = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.post(serviceUrl.getDBDocumentForID, requestObj);
  }

  createBackupCollection(requestObj: any) {
    let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.post(serviceUrl.createBackupCollection, requestObj);
  }

  updateDBCollectionDocument(requestObj: any) {
    let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    console.log('request object is:'+requestObj);
    console.log('request is:'+req);
    return this.post(serviceUrl.updateDBCollectionDocument, requestObj);
  }

  reloadDbCollectionUI(requestObj: any) {
    let url=serviceUrl.reloadDbCollectionUI+'?r='+requestObj;
    console.log('request url is:'+url);
    return this.get(url);
  }
}
