export class QmaConstant {
    public static appBaseContext: string = "/QMA";
    public static inquiryStatusEnum = {
        OPEN: "Open",
        RESOLVED: "Resolved",
        PENDINGAPPROVAL: "Pending Approval"
    }
    // Defined constant enum which will be initialized during user login values will come from server
    // type 0 defines view whereas type 1 defined folder.
    public static inboxGridViewType = {
        Inbox: { viewType: '0' },
        ChatView: { viewType: '0' },
        Snoozed: { viewType: '0' },
        'Auto Assign FYI': { viewType: '0' },
        Outbox: { viewType: '1' },
        Resolved: { viewType: '2' },
        'Pending Approval': { viewType: '3' },
        'Assigned To Me': { viewType: '6' },
        Unassigned: { viewType: '7' },
        Drafts: { viewType: '9' },
        Tag: { viewType: '10' },
        'Potential Escalations': {
            viewType: 11,
            viewFilter: {
                'Client Chaser': 'isClientChaseEscalation',
                'Conversation Count': 'isConvCountEscalation',
                'Response Time': 'responseTimeEscalationFlag',
                'Subject Based': 'isSubjectEscalation',
                'Pending Approval': 'ispendingApprovalEscalation',
                'Manual Escalation': 'isManualEscalation'
            }
        },
        CLIENTCHASER: {
            viewName: 'Potential Escalations',
            viewType: 11,
            filterType: 'isClientChaseEscalation'
        },
        CONVERSATIONCOUNT: {
            viewName: 'Potential Escalations',
            viewType: 11,
            filterType: 'isConvCountEscalation'
        },
        RESPONSETIME: {
            viewName: 'Potential Escalations',
            viewType: 11,
            filterType: 'responseTimeEscalationFlag'
        },
        SUBJECTBASED: {
            viewName: 'Potential Escalations',
            viewType: 11,
            filterType: 'isSubjectEscalation'
        },
        PENDINGAPPROVAL: {
            viewName: 'Potential Escalations',
            viewType: 11,
            filterType: 'ispendingApprovalEscalation'
        },
        MANUALESCALATION: {
            viewName: 'Potential Escalations',
            viewType: 11,
            filterType: 'isManualEscalation'
        },
        FollowUp: { viewType: '12' },
        'Open Inquiries By Assigned Group': {
            isChartView: 'Y'
        },
        //'Owner' --> 'Owners' to align with service API
        'Open Inquiries By Assigned Owners': {
            isChartView: 'Y'
        },
        'Open Inquiries By Request Type': {
            isChartView: 'Y'
        },
        'intensityHeatMap': {
            isChartView: 'Y'
        },
        'Non Inquiries': { viewType: '0' },
        defaultPageNo: "1",
        defaultPageSize: 100,
        paginationNext: "next",
        paginationPrev: "prev",
        // Multiple issues on scrolling , Issue 1- On scrolling Grid , the Grid footer does not update the record received count.
        maxPagesInCache: 10000,
    }
    public static typeEnum = {
        // This is a dummy view used for tab configuration.
        VIEW: { VW: 'View', TYPE: -1 },
        INBOX: { INBX: 'Inbox', TYPE: 0 },
        OUTBOX: { OUTBX: 'Outbox', TYPE: 1 },
        RESOLVEDBOX: { RELBX: 'Resolved', TYPE: 2 },
        PENDINGAPPROVAL: { PENDING: 'Pending Approval', TYPE: 3 },
        FOLDER: { FOL: 'Folder', TYPE: 4 }, // read only
        GLOBALSEARCH: { HW: 'Home', TYPE: 5, TITLE: 'Search' },
        // - Changed the name of the variable to match the tab title for column chooser.
        ASSIGNETOME: { ASSIGN: 'Assigned To Me', TYPE: 6 },// Inbox
        UNASSIGNEDMESSAGE: { UNASSIGNEDMESSAGE: 'Unassigned', TYPE: 7 }, // Inbox
        IMPORTANTMESSAGE: { IMPORTANTMESSAGE: 'IMPORTANTMESSAGE', TYPE: 8 }, // Inbox
        DRAFTS: { DRFT: 'Drafts', TYPE: 9 },// Drafts,
        TAG: { TAG: 'Tag', TYPE: 10 },
        CLIENTCHASER: {
            viewName: 'Potential Escalation',
            viewType: 11,
            filterType: 'isClientChaseEscalation'
        },
        ESCALATION: {
            ESC: 'Potential Escalations', TYPE: 11,
            'Client Chaser': 'isClientChaseEscalation', CONV_COUNT_FILED: 'isConvCountEscalation', RESP_TIME_FIELD: 'responseTimeEscalationFlag', SUB_BASED_FIELD: 'isSubjectEscalation', PEND_APPROV_FIELD: 'ispendingApprovalEscalation',
            MANUAL_ESC: 'isManualEscalation'
        },
        FOLLOWUP: { FLUP: 'FollowUp', TYPE: 12 },
        INQUIRYDIRECTION: {
            // This is a dummy view used for tab configuration.
            IN: "IN",
            OUT: "OUT",
            PENDINGAPPROVAL: "PENDINGAPPROVAL",
            PND_REAGE: 'PND_REAGE'
        },
    };

    public static reservedViewNames = ['Inbox', 'Sent', 'Pending Approval', 'Potential Escalations', 'Conversation Count',
        'Response Time', 'Subject Based', 'Pending Approval', 'Resolved', 'Assigned To Me', 'Unassigned',
        'Snoozed', 'Drafts', 'Non Inquiries', 'Auto Assign FYI'];

    public static inboxConfiguration = {
        // For details conversation history
        /**Jirapoint no3 scroll hidden from conv history so removed white space */
        detailRowHeight: 50,// row height: 55 -> 50
        detailRowHeightForOneRow: 30,   //row height: 35 -> 30//JIRA : 4656 :  extra line coming after grid
        detailRowHeightForNRows: 28, //row height: 33 -> 28
        contextMenu: 'contextMenu',
        gridRowHeight: 25 //row height: 30 -> 25
    }
    public static mailBoxDefaultCols = {
        // detailRowHeight: 100, to be reverted
        // detailRowHeight: 37,
        // For details conversation history
        detailRowHeight: 77,
        contextMenu: 'contextMenu',
        gridRowHeight: 30
    }
    public static MailBoxGridFeaturedCols = {
        colIdCheckbox: 'expander',
        colIdXheveron: 'cheveron',
        colIdDrag: 'cheveron_1',
        colIdCheckboxWidth: 31,
        colIdXheveronWidth: 20,
        colIdDragWidth: 20,
    }
    // Recipient Type
    public static FROM: string = 'FROM';
    public static TO: string = 'TO';
    public static CC: string = 'CC';
    public static BCC: string = 'BCC';


    // Rest Api URLs
    // public static BASE_URL: string = '/QMA2';  It is reqiured for Local development
    //- Fetch the application context from the URL, or local it takes /QMA2
    //public static BASE_URL: string = window.location.pathname.substr(0, window.location.pathname.length - 1) || '/QMA2';
    public static BASE_URL: string = 'https://qa-qmauiapi.dev.qwyn.deltacapita.net/QMA2' || '/QMA2';

    // Symphony endpoints
    public static SYMPHONY_BASE_URL: string = window.location.origin.includes('XXXXX') ?
        window.location.origin + '/cvsym' : "/symphony";
    // Download content type
    public static PRINT: string = 'print';
    public static PDF: string = 'pdf';
    public static EMAIL: string = 'email';

    // Conversation Reply type
    public static REPLY: string = 'Reply';
    public static REPLY_ALL: string = 'ReplyAll';
    public static REPLY_THANK_YOU: string = 'ReplyThank';
    public static REPLY_LATER: string = 'Replylater';
    public static REASSIGN: string = 'Forward';
    public static QUICK_REPLY: string = 'QuickReply';
    public static RE_AGE: string = 'ReAge';

    public static domainListAllowed = ['.com', '.in', '.us', '.uk', '.org', '.net', '.io', '.co', '.info', '.mobi'];

    // User - Setting Constants
    public static conversationView: string[] = ["Horizontal", "Vertical", "ChatView"];

    // Defualt QMA URL for Local and Dev Environment
    public static QMA_URL = "https://XXXXX";

    // QMA drag/drop header prefix
    public static QMA_DND_HEADER = "qma_dnd_";

    //Mobile width breakpoint
    public static MOBILE_WIDTH = 768;

    //: NLP Suggestion Start and End tags
    public static START_NLP_SUGGESTION = '<div><span id="startingSuggestionConentElement"></span></div>';
    public static END_NLP_SUGGESTION = '<div><span id="trailingSuggestionContentsElement"></span></div>';


    //Context Menu TAB based Model
    //Update for unfollow option context menu
    public static ContextMenuMobile = {
        'RESOLVED': ['Assign Owner', 'Assign To Me', 'Reopen', 'Assign Inquiry Source', 'Assign Request Type', 'Assign Tags', 'Assign GFPID/GFCID', 'Link Exception', 'Mark as Read', 'Mark as Unread'], 
        'SENT': ['Assign Owner', 'Assign To Me', 'Follow Up', 'Unfollow', 'Assign Inquiry Source', 'Assign Processing Region', 'Assign Request Type', 'Assign Tags', 'Assign GFPID/GFCID', 'Link Exception', 'Notify', 'Snooze'],
        'PENDING APPROVAL': ['Approve', 'Link Exception', "Mark as Read", "Mark as Unread"], // allow Mark-as-Read/UnRead on Pending Approvals
        'ESC: PENDING APPROVAL': ['Approve', 'Reject', 'De-escalate', 'Link Exception', 'Mark as Read', 'Mark as Unread'], 
        'POTENTIAL ESCALATIONS': ['Assign Owner', 'Assign To Me', 'De-escalate', 'Assign Inquiry Source', 'Assign Processing Region', 'Assign Request Type', 'Assign Tags', 'Assign GFPID/GFCID', 'Resolve', 'Lock', 'Follow Up', 'Unfollow', 'Link Exception', 'Mark as Read', 'Mark as Unread', 'Re-Age', 'Ownership', 'Notify', 'Snooze'],
        'DRAFTS': ['Delete'],
        // add Link, Delink, Unlock to default case,
        'DEFAULT': ['Assign Owner', 'Assign To Me', 'Resolve', 'Assign Inquiry Source', 'Assign Processing Region', 'Assign Request Type', 'Assign Tags', 'Assign GFPID/GFCID', 'Link', 'De-Link', 'Lock', 'Unlock', 'De-escalate', 'Follow Up', 'Unfollow', 'Link Exception', 'Mark as Read', 'Mark as Unread', 'Re-Age', 'Ownership', 'Notify', 'Snooze', 'Un-Snooze'/* JIRA C153176-4624 Mobile (CR) - Snooze*/]
    };
    //right swipe menu spec - optional
    public static ContextMenuMobileRSwipe = {
        'PENDING APPROVAL': ['Approve', 'Reject', 'Link Exception', 'Mark as Read', 'Mark as Unread']// allow Mark-as-Read/UnRead on Pending Approvals
    };
    public static BASE64_SECRET_KEY = "6C94397D2DF637E58CB0A8";
    //websocket reconnect retry times, wait intervals
    public static MAX_WEBSOCKET_RECONNECT_TRY_TIMES = 50;

    public static NO_SUBJECT = 'no-subject';

    public static FEEDBACK_OPTIONS = [
        { 'name': 'emailDisclaimer', 'label': 'Email Disclaimer' },
        { 'name': 'signature', 'label': 'Signature' },
        { 'name': 'to', 'label': 'To' },
        { 'name': 'cc', 'label': 'Cc' },
        { 'name': 'subject', 'label': 'Subject' },
        { 'name': 'blankBubble', 'label': 'Blank bubble' },
    ];

    // getDashboardCounts interval - default 1 min
    public static GETDASHBOARDCOUNTS_INERVAL_IN_MS = 60 * 1000;

    public static MOBILE_DEFAULT_SEQUENCE = ['Assigned to me', 'Pending Approval', 'Escalation', 'Inbox']; //Mobile-Order of the icons in mobile
    public static QMA_OPEN_INQ_BYASSIGNEDGROUP = "Open Inquiries By Assigned Group";
    public static QMA_OPEN_INQ_BYREQUESTTYPE = "Open Inquiries By Request Type";
    public static QMA_OPEN_INQ_BY_OWNER = "Open Inquiries By Assigned Owners";

    public static ATTACHMENT_ALLOWED_SIZE = 15 * 1024 * 1024;
    // All Attachment size i.e. 60 MB
    public static ALL_ATTACHMENT_SIZE = 60 * 1024 * 1024;

    // Default mabbox statistics
    public static MAIL_BOX_STATS = {
        "messages": ["Inbox", "Resolved", "Pending Approval", "Potential Escalation"],
        "myViews": []
    };

    // Rejection Reasons
    public static REJECTION_REASON = [
        { 'value': 'none', 'label': 'Select the Rejection Reason' },
        { 'value': 'inaccurate', 'label': 'NLP response is inaccurate' },
        { 'value': 'notForGroup', 'label': 'NLP request not for this group.' },
        { 'value': 'allReference', 'label': 'NLP suggestion not pulled for all trade references.' },
        { 'value': 'outdated', 'label': 'NLP Suggestion outdated e.g. cancelled trade status etc' },
        { 'value': 'internal', 'label': 'NLP response on internal conversation' },
        { 'value': 'noResRequire', 'label': 'No response require to NLP suggestion' }, // C170665-65 | Add additional objection in NLP rejection dropdown
        { 'value': 'notTradeStatusInq', 'label': 'Not a trade status request' },
        { 'value': 'others', 'label': 'Others' },
    ];

    // symphony actions
    public static symphonyChatActionsEnum = {
        NEWCHAT: "New Chatroom",
        ADDEDMEMBER: "add member",
        REMOVEDMEMBER: "remove member"
    }

    // Profile- Setting Constants
    public static exhangeRegions: string[] = ["APAC", "EMEA", "NAM"];

    // Personal Folder
    public static QMA_PERSONAL: string = 'QMA PERSONAL';

     // C170665-255 Restrict specific file extensions in QMA 2.0
    public static QMA_VALID_FILES = ["image/jpeg","image/png"];
    public static QMA_INVALID_FILES_MSG = 'Below uploaded file extensions are not supported in QMA.' +
                                           'Please refer to the information icon for more details.';
    public static QMA_BLOCKED_FILE_NAME_CHARS = 
    `The uploaded file name contains special characters which is replaced by _ ` +  'Please refer to information icon for more details';
    public static QMA_UPLOAD_FILES_INFO = 
   /*  `List of extensions restricted to upload:-\n` + */
    `.ade,.adp,.asd,.asf,.asx,.bas,.bat,
    .chm,.cil,.class,.cmd,.com,.cpl,.crt,
    .dat,.dll,.dot,.eml,.exe,.hcp,
    .hlp,.hta,.hte,.inf,.js,
    .jse,.lnk,.mda,.mdb,.mde,.mdw,.msc,
    .msi,.msp,.nws,.ocx,.pif,.pl,.pm,
    .pot,.pps,.reg,.rtf,.scr,.sct,
    .shb,.shs,. sys,.vb,.vbe,.vbs,.vcf,
    .wmd,.wms,.wmz,.wsc,.wsf,.wsh,
    .xlt,.xlw,.zlb`;

    
    
    public static QMA_INVALID_FILE_EXTENSIONS = ["ade","adp","asd","asf","asx","bas","bat",
    "chm","cil","class","cmd","com","cpl","crt","dat","dll","dot","eml","exe","hcp",
    "hlp","hta","hte","inf","js","jse","lnk","mda","mdb","mde","mdw","msc",
    "msi","msp","nws","ocx","pif","pl","pm","pot","pps","reg","rtf","scr","sct",
    "shb","shs","sys","vb","vbe","vbs","vcf","wmd","wms","wmz","wsc","wsf","wsh",
    "xlt","xlw","zlb"];
    public static QMA_VALID_FILE_EXTENSIONS =
        ["docx", "pdf", "xls", "xlsx", "png", "jpeg", "ppt", "pptx", "jpg", "msg",
            "eml", "txt", "doc", "csv", "zip" ];
    public static DEFAULT_PERSONAL_MAIL_FOLDERS = ['INBOX', 'DRAFTS', 'SENT ITEMS', 'DELETED ITEMS', 'JUNK EMAIL', 'OUTBOX'];

    // CLC and NLP Column Delete Contants
    public static CLC_DELETE_SPAN = '<span class="deleteColumnClc" style="float: right; cursor: pointer;">x</span>';

    public static NLP_DELETE_SPAN = '<span class="deleteColumnNlp" style="float: right; cursor: pointer;">x</span>';
    public static NLP_DELETE_SPAN_REDESIGNED = '<b class="deleteColumnNlp" style="margin-left: 10px; cursor: pointer;position: absolute;right: 2px;">x</b>';

    // C170665-1061 Mailbox stats changes for Resolved and Sent box
    // dont update tooltip for resolved and sent
    public static MAILBOX_STATS_EXCLUDE_TOOLTIP_CHARTS = ['RESOLVED', 'SENT'];

    public static QMA_IDSITE = 224;
    public static CHAT_VIEW = "CHATVIEW";
    public static tinyMCEFontsNotSelected = 'None';


    public static SUPPORT_FOR_UKRAINE_URL = 'https://www.XXXXX/EN/Pages/Global/News/support-for-ukraine.aspx';
    public static EDITOR_FONT_NAME_ID: string = "editorFontNameId";
    public static EDITOR_FONT_SIZE_ID: string = "editorFontSizeId";

    public static preMatchedStatusEnum = {
        REVIEWED: "REVIEWED",
        APPROVED: "APPROVED",
        CANCELLED: "CANCELLED"
    }

    public static NLP_TRADE_VALIDATION_FIELDS = ['Clnt Mnem',  'Acct Nm', 'Agt Nm', 'Alert Acronym', 'GP Match','Gp Number', 'Bus Area', 'Ctp Serv Loc','Firm Acct Mnem'];
    public static NLP_TRADE_VALIDATION_FIELDS_MAPPING = 
    [
        {apiField:'GP Match',displayHeader:'GP Match'},
        {apiField:'Alert Acronym',displayHeader:'Alert Acronym'},
        {apiField:'GP_NAME',displayHeader:'GP Name'},
        {apiField:'Acct Nm',displayHeader:'Account Name'},
        {apiField:'Clnt Mnem',displayHeader:'Mnemonic'},
        {apiField:'Gp Number',displayHeader:'Gp Number'},
    ];
    public static NLP_FEEDBACK_ATTRIBUTES = ["Con Qty","Trd Dt","Cusip Code","Con_Setl_Dt","Isin Code"];
    public static QMA_MAIL_SPLIT_MORE_ICON = '<img  alt="Expand/Collapse" class="expandMsg" src="assets/Inbox/view-more-message.svg" id="convSplitMoreIcon">';
    public static QMA_MAIL_VIEW_MORE_ICON_ID = 'convSplitMoreIcon'
    public static QMA_TRADE_DETAIL_PREDIFINED_COLUMNS = ["Ops Trd Ref","Status"]
    public static QMA_TRADE_DETAIL_HIDDEN_COLUMNS = ["GP_NAME"]
    public static QMA_NLP_TRADE_THRESHOLD = 20; 
    public static QMA_NLP_nlpRefreshRequestColumns =['Ops Trd Ref','Ops Setl Trd Ref'];
    public static SMARTSEARCHChARACTERSLimit = 100;
    public static QMA_NEW_BUBBLE ='NEW BUBBLE';
   
}